import dynamic from "next/dynamic";

const moduleAssessmentQuestionnaire = dynamic(
  () =>
    import(
      "@/features/assessment-questionnaire/components/AssessmentQuestionnaire"
    )
);
const moduleBreaker = dynamic(() => import("@/components/Breaker"));
const moduleCardGrid = dynamic(() => import("@/components/CardGrid"));
const moduleComponentProxy = dynamic(
  () => import("@/components/ComponentProxy")
);
const moduleEditorialRatingsAndReviews = dynamic(
  () => import("@/components/EditorialRatingsAndReviews")
);
const moduleEmbed = dynamic(() => import("@/components/Embed"));
const moduleBrandEquinoxClubs = dynamic(
  () => import("@/components/ExploreClubs")
);
const moduleFormerMemberHeroLeadFlow = dynamic(
  () => import("@/components/FormerMemberHeroLeadFlow")
);
const moduleHeader = dynamic(() => import("@/components/GlobalHeader"));
const moduleCategoriesNavigation = dynamic(
  () => import("@/components/GroupFitnessClassCategoryList")
);
const moduleHighlightsWithPhotoBackground = dynamic(
  () => import("@/components/HighlightsWithPhotoBackground")
);
const moduleJoinFlow = dynamic(() => import("@/components/JoinFlow"));
const moduleMembershipOption = dynamic(
  () => import("@/components/MembershipOption")
);
const moduleNotificationBanner = dynamic(
  () => import("@/components/NotificationBanner")
);
const modulePageHero = dynamic(() => import("@/components/PageHero"));
const moduleStickyHeader = dynamic(() => import("@/components/StickyHeader"));
const moduleTextMarkdown = dynamic(() => import("@/components/TextMarkdown"));
const moduleSuggestedLocations = dynamic(
  () => import("@/components/SuggestedLocations")
);
const moduleTiled = dynamic(() => import("@/components/TiledComponent"));
const moduleAccordion2Column = dynamic(() => import("@/components/ClubInfo"));
const moduleCallout = dynamic(() => import("@/components/callout"));
const moduleCarouselWithDescriptionCard = dynamic(
  () => import("@/components/carousels/withDescriptionCard")
);
const moduleClassInstanceSummary = dynamic(
  () => import("@/components/ClassInstanceSummary")
);
const moduleClubClasses = dynamic(() => import("@/components/ClubClasses"));
const moduleSportsClubCommunity = dynamic(
  () => import("@/components/club/sportsCommunity")
);
const moduleEditorialAccordion = dynamic(
  () => import("@/components/editorial/accordion")
);
const moduleEditorialArticleScroller = dynamic(
  () => import("@/components/EditorialArticleScroller")
);
const moduleEditorialCommonQuestions = dynamic(
  () => import("@/components/editorial/commonQuestions")
);
const moduleEditorialFullWidth = dynamic(
  () => import("@/components/editorial/fullWidth")
);
const moduleEditorialGrid2Column = dynamic(
  () => import("@/components/EditorialGrid2ColumnModule")
);
const moduleEditorialSingleColumn = dynamic(
  () => import("@/components/editorial/singleColumn")
);
const moduleEditorialSingleImage = dynamic(
  () => import("@/components/editorial/singleImage")
);
const moduleElevatedExperience = dynamic(
  () => import("@/components/elevated-experience")
);
const moduleFooter = dynamic(() => import("@/components/StarkCta"));
const moduleForm = dynamic(() => import("@/components/form/dynamic"));
const moduleGallery = dynamic(() => import("@/components/Gallery"));
const moduleClassCategoryFeatureClass = dynamic(
  () => import("@/components/gf-feature-class")
);
const moduleHomepageHeroCampaigns = dynamic(
  () => import("@/components/hero/campaigns")
);
const moduleHeroClassSchedule = dynamic(
  () => import("@/components/ClassSchedule")
);
const moduleHeroClub = dynamic(() => import("@/components/ClubPageHero"));
const moduleLeadFlow = dynamic(
  () => import("@/features/lead-flow/components/LeadFlow")
);
const moduleOnDemandLandscape = dynamic(
  () => import("@/components/on-demand/landscape")
);
const moduleOnDemand = dynamic(() => import("@/components/OnDemand"));
const moduleOnDemandSingleImage = dynamic(
  () => import("@/components/on-demand/singleImage")
);
const moduleOtherLocations = dynamic(
  () => import("@/components/other-location")
);
const moduleOverlappedColumns = dynamic(
  () => import("@/components/overlapped-columns")
);
const moduleSingleImageAccordion = dynamic(
  () => import("@/components/single-image/accordion")
);
const moduleSingleVideo = dynamic(() => import("@/components/single-video"));
const moduleText = dynamic(() => import("@/components/text"));
const moduleTextBreakTheLove = dynamic(() => import("@/components/text"));

const MODULES = {
  moduleAccordion2Column,
  moduleAssessmentQuestionnaire,
  moduleBrandEquinoxClubs,
  moduleBreaker,
  moduleCallout,
  moduleCardGrid,
  moduleCarouselWithDescriptionCard,
  moduleCategoriesNavigation,
  moduleClassCategoryFeatureClass,
  moduleClassInstanceSummary,
  moduleClubClasses,
  moduleComponentProxy,
  moduleEditorialAccordion,
  moduleEditorialArticleScroller,
  moduleEditorialCommonQuestions,
  moduleEditorialFullWidth,
  moduleEditorialGrid2Column,
  moduleEditorialRatingsAndReviews,
  moduleEditorialSingleColumn,
  moduleEditorialSingleImage,
  moduleElevatedExperience,
  moduleEmbed,
  moduleFooter,
  moduleForm,
  moduleFormerMemberHeroLeadFlow,
  moduleGallery,
  moduleHeader,
  moduleHeroClassSchedule,
  moduleHeroClub,
  moduleHighlightsWithPhotoBackground,
  moduleHomepageHeroCampaigns,
  moduleJoinFlow,
  moduleLeadFlow,
  moduleMembershipOption,
  moduleNotificationBanner,
  moduleOnDemand,
  moduleOnDemandLandscape,
  moduleOnDemandSingleImage,
  moduleOtherLocations,
  moduleOverlappedColumns,
  modulePageHero,
  moduleSingleImageAccordion,
  moduleSingleVideo,
  moduleSportsClubCommunity,
  moduleStickyHeader,
  moduleSuggestedLocations,
  moduleText,
  moduleTextBreakTheLove,
  moduleTextMarkdown,
  moduleTiled,
};

export default MODULES;
